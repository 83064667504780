import { template as template_d00f67b92049458daf44921bc58e2c19 } from "@ember/template-compiler";
const WelcomeHeader = template_d00f67b92049458daf44921bc58e2c19(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
