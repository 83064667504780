import { template as template_cd3a307d8ae64bf7ac0a7513bf2c41ef } from "@ember/template-compiler";
const FKLabel = template_cd3a307d8ae64bf7ac0a7513bf2c41ef(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
