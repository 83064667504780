import { template as template_d6d17342de4748c485e5f8c9a9e5253b } from "@ember/template-compiler";
const FKText = template_d6d17342de4748c485e5f8c9a9e5253b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
